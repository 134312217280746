import Vue from 'vue'
import VueRouter from 'vue-router'
// import Parse from "parse";
// import store from "@/store";
import {RoleService} from "@/services/RoleService";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Your Productions',
      },
    },
    {
      path: '/production/:prodId/calendar',
      name: 'calendar',
      component: () => import('@/views/Calendar.vue'),
      meta: {
        pageTitle: 'Calendar',
        breadcrumb: [
          {
            text: 'Calendar',
            active: true,
          },
        ],
      },
    },

    {
      path: '/production/:prodId',
      component: () => import('@/views/ProductionParent.vue'),
      beforeEnter: (to, from, next) => {
          RoleService.getProductionRoles(to.params.prodId).then(() => {
            next();
          }, (err) => {
            console.error(err);
          });
      },
      children: [
        {
          path: '',
          redirect: 'overview', // default child path
        },
        {
          path: 'overview',
          name: 'overview',
          component: () => import('@/views/Overview.vue'),
          beforeEnter: (to, from, next) => {
            next();
          },
          meta: {
            pageTitle: 'Overview',
            breadcrumb: [
              {
                text: 'Overview',
                active: true,
              },
            ],
          },
        },
        {
          path: 'team',
          name: 'team',
          component: () => import('@/views/Team.vue'),
          meta: {
            pageTitle: 'Team',
            breadcrumb: [
              {
                text: 'Team',
                active: true,
              },
            ],
          },
        },
        {
          path: 'roles/:roleId/:tab',
          name: 'roleDetails',
          component: () => import('@/views/CharacterDetails.vue'),
          meta: {
            pageTitle: 'Role Details',
            breadcrumb: [
              {
                text: 'Role Details',
                active: true,
              },
            ],
          },
        },
        {
          path: 'roles',
          name: 'roles',
          component: () => import('@/views/RoleList.vue'),
          children: [

          ],
          meta: {
            pageTitle: 'Roles and Characters',
            breadcrumb: [
              {
                text: 'Roles',
                active: true,
              },
            ],
          },
        },
      ],
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/prospects',
      name: 'prospects',
      component: () => import('@/views/Prospects.vue'),
    },
    {
      path: '/talent/:roleId',
      name: 'talent-landing',
      component: () => import('@/views/TalentLanding.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/calendar-basic',
      name: 'calendar-basic',
      component: () => import('@/views/apps/calendar/Calendar.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/password-recover',
      name: 'passwordRecover',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recover-successful',
      name: 'BackToLogin',
      component: () => import('@/views/BackToLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/my-profile',
      name: 'my-profile',
      component: () => import('@/views/MyProfile.vue'),
      meta: {
        pageTitle: 'My Profile',
        breadcrumb: [
          {
            text: 'MyProfile',
            active: true,
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],

})

export default router
