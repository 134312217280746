export default {
	namespaced: true,
	state: {
		genres: [
			{ value: 'action', text: 'Action' },
			{ value: 'adventure', text: 'Adventure' },
			{ value: 'animation', text: 'Animation' },
			{ value: 'biography', text: 'Biography' },
			{ value: 'comedy', text: 'Comedy' },
			{ value: 'crime', text: 'Crime' },
			{ value: 'documentary', text: 'Documentary' },
			{ value: 'drama', text: 'Drama' },
			{ value: 'family', text: 'Family' },
			{ value: 'fantasy', text: 'Fantasy' },
			{ value: 'film_noir', text: 'Film Noir' },
			{ value: 'history', text: 'History' },
			{ value: 'horror', text: 'Horror' },
			{ value: 'musical', text: 'Musical' },
			{ value: 'mystery', text: 'Mystery' },
			{ value: 'romance', text: 'Romance' },
			{ value: 'racing', text: 'Racing' },
			{ value: 'scifi', text: 'Sci-Fi' },
			{ value: 'short', text: 'Short Film' },
			{ value: 'sport', text: 'Sport' },
			{ value: 'superhero', text: 'Superhero' },
			{ value: 'thriller', text: 'Thriller' },
			{ value: 'war', text: 'War' },
			{ value: 'western', text: 'Western' },
		],
		types: [
			{ value: 'advert', text: 'Advertisement' },
			{ value: 'animation', text: 'Animation' },
			{ value: 'movie', text: 'Movie' },
			{ value: 'series', text: 'Series' },
		],
		roleTypes: [
			{ value: 'lead', text: 'Lead' },
			{ value: 'supporting', text: 'Supporting' },
			{ value: 'cameo', text: 'Cameo' },
			{ value: 'day_player', text: 'Day Player' },
		],
		bodyTypes: [
			{ value: 'average', text: 'Average' },
			{ value: 'slim', text: 'Slim' },
			{ value: 'athletic', text: 'Athletic / Toned' },
			{ value: 'muscular', text: 'Muscular' },
			{ value: 'curvy', text: 'Curvy' },
			{ value: 'heavy', text: 'Heavy set / Stocky' },
			{ value: 'plus', text: 'Plus size / Full figured' },
		],
		skills: [
			{value: "diving",text: "Diving"},
			{value: "hiking", text: "Hiking"},
			{value: "horse_riding",text: "Horse Riding"},
			{value: "driving_manual",text: "Driving Manual"},
			{value: "driving_auto",text: "Driving Automatic"},
			{value: "singing",text: "Singing"},
			{value: "stunts",text: "Stunts"},
			{value: "swimming",text: "Swimming"},
			{value: "weapons",text: "Weapons handling"},
		],
		ethnicities: [
			{value: "asian", text: "Asian"},
			{value: "black", text: "Black / African Descent"},
			{value: "caucasian",text: "White"},
			{value: "indigenous", text: "Indigenous Peoples"},
			{value: "indian",text: "Indian / South Asian"},
			{value: "pacific",text: "South-East Asian / Islander"},
			{value: "latino",text: "Latino / Hispanic"},
			{value: "arabic",text: "Middle Eastern"},
			{value: "mixed",text: "Multiracial"},
			{value: "white",text: "White"},
		],
		hairColors : [
			{value: "blonde",text: "Blond"},
			{value: "black", text: "Black"},
			{value: "brunette", text: "Brunette"},
			{value: "redhead",text: "Redhead"},
			{value: "gray",text: "Gray"},
			{value: "white",text: "White"},
			{value: "other",text: "Other"},
		],
		eyeColors : [
			{value: "all",text: "All"},
			{value: "black",text: "Black"},
			{value: "brown",text: "Brown"},
			{value: "green", text: "Green"},
			{value: "grey",text: "Grey"},
			{value: "hazel", text: "Hazel"},
		],
		languages : [
			{value: "ar_ar", text: "Arabic", 			short: "AR"},
			{value: "en_us", text: "American English",  short: "EN (US)"},
			{value: "en_uk", text: "British English",  	short: "EN (UK)"},
			{value: "en_au", text: "Australian English",  short: "EN (AU)"},
			{value: "en_en", text: "International English", short: "EN"},
			{value: "en_ie", text: "Irish English", 	short: "EN (IE)"},
			{value: "bg_bg", text: "Bulgarian", 		short: "BG"},
			{value: "de_de", text: "German (Hochdeutsch)", short: "DE"},
			{value: "de_at", text: "Austrian German",	short: "DE (AT)"},
			{value: "de_ch", text: "Swiss German", 		short: "DE (CH)"},
			{value: "fr_fr", text: "French", short: "FR"},
			{value: "ru_ru", text: "Russian", short: "RU"},
			{value: "es_es", text: "Spanish (Castellano)", short: "ES"},
			{value: "es_ar", text: "Spanish (Argentina)", short: "ES (AR)"},
		],
		genders : [
			{value: "",text: "Any"},
			{value: "female",text: "Female"},
			{value: "male",text: "Male"},
			{value: "other", text: "Other"},
		],
	},
	getters: {
		productionGenres: state => {
			return state.genres;
		},
		productionTypes: state => {
			return state.types;
		},
		roleSkills: state => {
			return state.skills;
		},
		bodyTypes: state => {
			return state.bodyTypes;
		},
		roleTypes: state => {
			return state.roleTypes;
		},
		ethnicities: state => {
			return state.ethnicities;
		},
		hairColors: state => {
			return state.hairColors;
		},
		eyeColors: state => {
			return state.eyeColors;
		},
		languages: state => {
			return state.languages;
		},
		genders: state => {
			return state.genders;
		},
		getRoleDicts: state=> {
			let allDicts = {
				skills: state.skills,
				bodyTypes: state.bodyTypes,
				roleTypes: state.roleTypes,
				ethnicities: state.ethnicities,
				hairColors: state.hairColors,
				eyeColors: state.eyeColors,
				languages: state.languages,
				genders: state.genders,
			}
			return allDicts;
		}
	},
}
