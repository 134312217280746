import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBToggle } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import moment from "moment"

const Parse = require('parse');
Parse.initialize(`${process.env.VUE_APP_PARSE_APP_ID}`, `${process.env.VUE_APP_PARSE_APP_SECRET}`);
Parse.serverURL = `${process.env.VUE_APP_PARSE_URL}`;


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.directive('b-toggle', VBToggle)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    truncateString(str, max, suffix) {
      return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
    },
    getSkillList(){
      return [
        {key: "driving_manual", text: "Driving Manual"},
      ]
    },
    defaultLogo(format){
      if(format == 'svg') {
        return require('@/assets/images/logo/placeholder.svg');
      } else if (format == 'svg-white'){
          return require('@/assets/images/logo/placeholder-white.svg');
      }else{
        return require('@/assets/images/logo/logo.png');
      }
    },
    qfSvgIcon(iconName){
      return require('@/assets/images/icons/'+iconName+'.svg');
    },
    niceDate(date){
      return moment(date).format("DD-MMM-YYYY");
    },
    niceFileName(filename) {
      if (filename) {
        return filename.substring(33);
      } else {
        return "";
      }
    },

  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
