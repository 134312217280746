import Parse from "parse";
import store from "@/store";

export const RoleService = {
    getProductionRoles(prodId) {
        return new Promise((resolve, reject) => {
            let ActingRole = Parse.Object.extend('ActingRole');
            let RoleQuery = new Parse.Query(ActingRole);

            const productionPointer = { "__type": "Pointer", "className": "Production", "objectId": prodId };
            RoleQuery.equalTo("production", productionPointer);
            RoleQuery.include("talentPick");

            RoleQuery.find()
                .then(function(results){
                    if(results.length){
                        results.forEach(function(oneResult){
                            if(oneResult.attributes.shortlistedTalent){
                                let userQuery = new Parse.Query(Parse.User);
                                userQuery.containedIn("objectId", oneResult.attributes.shortlistedTalent);
                                userQuery.include("relatedMeta");
                                userQuery.find().then(function(users){
                                    store.commit('app/ADD_SHORTLISTED', {roleUsers: users, roleId: oneResult.id});
                                    store.commit('app/REFRESH_ROLES');
                                });
                            }
                        });
                    }

                    let shootingLocation = Parse.Object.extend('ShootingLocation');
                    let LocationQuery = new Parse.Query(shootingLocation);

                    LocationQuery.equalTo('relatedRole', productionPointer);
                    LocationQuery.find()
                        .then(function(locResults) {
                            store.commit('app/SET_LOCATIONS', locResults);
                        })
                        .catch(function(err) {
                            console.log('Parse error', err.message);
                        });
                    store.commit('app/SET_ALL_ROLES', results);
                    resolve(results)
                })
                .catch(function(err){
                    console.log("Parse error", err.message);
                    reject(err)
                });
        })
    },
}