import {$themeBreakpoints} from '@themeConfig'
import Parse from "parse";

export default {
    namespaced: true,
    state: {
        liveAuditions: [],
        auditions: [],
        curLiveAudition: {
            attributes: {}
        },
        applicants: [],
        windowWidth: 0,
        shallShowOverlay: false,
        curProduction: null,
        allProductions: [],
        curAudition: {
            attributes: {}
        },
        allRoles: [],
        curRole: {
            attributes: {}
        },
        curInvitation: {
            attributes: {}
        },
        shortlistedTalents: [],
        shootingLocations: [],
        castingLocation: {
            attributes: {}
        },
        sides: [],
        invitations: [],
        billingCompanies: [],
        companyToEdit: null,
        teamMembers: [],
        talentToPreview: {},
        talentSearchResults: [],
        talentListResults: [],
        isAdmin: false,
        uploadProgresses: {
            fileUpload: 0,
        },
    },
    getters: {
        uploadProgresses(state) {
            return state.uploadProgresses;
        },
        isAdmin: state => {
            return state.isAdmin;
        },
        allLiveAuditions: state => {
            return state.liveAuditions;
        },
        currentLiveAudition: state => {
            return state.curLiveAudition;
        },
        talentSearchResults: state => {
            return state.talentSearchResults;
        },
        talentListResults : state => {
            return state.talentListResults;
        },
        currentBreakPoint: state => {
            const {windowWidth} = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },
        allApplicants: state => {
            return state.applicants;
        },
        currentProduction: state => {
            return state.curProduction;
        },
        currentRole: state => {
            return state.curRole;
        },
        allProductions: state => {
            return state.allProductions;
        },
        allCompanies: state => {
            return state.billingCompanies;
        },
        allMembers: state => {
            return state.teamMembers;
        },
        allRoles: state => {
            return state.allRoles;
        },
        companyToEdit: state => {
            return state.companyToEdit;
        },
        talentToPreview: state => {
            return state.talentToPreview;
        },
        shortlistedTalents: state => {
            return state.shortlistedTalents;
        },
        castingLocation: state => {
            return state.castingLocation;
        },
        shootingLocations: state => {
            return state.shootingLocations;
        },
        sides: state => {
            return state.sides;
        },
        invitations: state => {
            return state.invitations;
        },
        currentInvitation: state => {
            return state.curInvitation;
        },
        currentAudition: state => {
            return state.curAudition;
        }
    },
    mutations: {
        SET_UPLOAD_PROGRESS(state, payload) {
            state.uploadProgresses[payload.progressName] = payload.progress;
        },
        SET_IS_ADMIN(state, val){
            state.isAdmin = val !== null ? val : false
        },
        SET_CASTING_LOCATION(state, val){
            state.castingLocation = val !== null ? val : { attributes: {} }
        },
        CLEAR_ROLE(state){
            state.curRole = {
                attributes: {}
            };
        },
        ADD_LIVE_AUDITION(state, val) {
            state.liveAuditions.unshift(val)
        },
        SET_LIVE_AUDITIONS(state, val) {
            state.liveAuditions = val !== undefined ? val : null
        },
        SET_LIVE_AUDITION(state, val){
            state.curLiveAudition = {};
            state.curLiveAudition = val;
        },
        REMOVE_LIVE_AUDITION(state, val) {
            state.liveAuditions = state.liveAuditions.filter(
                function (obj) {
                    return obj.id !== val.id;
                }
            );
        },
        UPDATE_LIVE_AUDITIONS(state, newLiveAudition){
            state.liveAuditions.forEach(function (oneLiveAudition, index) {
                if (oneLiveAudition.id === newLiveAudition.id) {
                    state.liveAuditions[index] = newLiveAudition;
                    state.liveAuditions = [...state.liveAuditions];
                }
            });
        },
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        SET_PRODUCTION(state, val) {
            state.curProduction = val !== undefined ? val : null
        },
        SET_ALL_PRODUCTIONS(state, val) {
            state.allProductions = val !== undefined ? val : null
        },
        SET_ADD_PRODUCTION(state, val) {
            state.allProductions.unshift(val)
        },
        UPDATE_PRODUCTIONS(state, newProduction){
            state.allProductions.forEach(function (oneProd, index) {
                if (oneProd.id === newProduction.id) {
                    // $th.$set(state.billingCompanies, index, newCompany);
                    //state.billingCompanies.splice(index, 1);
                    state.allProductions[index] = newProduction;
                    state.allProductions = [...state.allProductions];
                }
            });
        },
        SET_ADD_COMPANY(state, val) {
            state.billingCompanies.unshift(val)
        },
        SET_COMPANIES(state, val) {
            state.billingCompanies = val !== undefined ? val : null
        },
        UPDATE_COMPANIES(state, newCompany){
            state.billingCompanies.forEach(function (oneCompany, index) {
                if (oneCompany.id === newCompany.id) {
                    // $th.$set(state.billingCompanies, index, newCompany);
                    //state.billingCompanies.splice(index, 1);
                    state.billingCompanies[index] = newCompany;
                    state.billingCompanies = [...state.billingCompanies];
                }
            });
        },
        REMOVE_COMPANY(state, val) {
            state.billingCompanies = state.billingCompanies.filter(
                function (obj) {
                    return obj.id !== val.id;
                }
            );
        },
        UPDATE_ROLE(state, newRole){
            state.allRoles.forEach(function (oneRole, index) {
                if (oneRole.id === newRole.id) {
                    // $th.$set(state.billingCompanies, index, newCompany);
                    //state.billingCompanies.splice(index, 1);
                    state.allRoles[index] = newRole;
                    state.allRoles = [...state.allRoles];
                }
            });
        },
        SET_ALL_ROLES(state, val) {
            state.allRoles = val !== undefined ? val : null
        },
        REFRESH_ROLES(state){
            state.allRoles = [...state.allRoles];
        },
        SET_ROLE(state, val) {
            state.curRole = {};
            state.curRole = val !== undefined ? val : {}
        },
        ADD_ROLE(state, val) {
            state.allRoles.unshift(val)
        },
        REMOVE_ROLE(state, val) {
            state.allRoles.unshift(val)
            state.allRoles = state.allRoles.filter(
                function (obj) {
                    return obj.id !== val.id;
                }
            );
        },
        SET_MEMBERS(state, val) {
            state.teamMembers = val !== undefined ? val : null
        },
        ADD_NEW_MEMBER(state, newMember){
            state.teamMembers.push(newMember);
        },
        REMOVE_MEMBER(state, memberIndex){
            state.teamMembers.splice(memberIndex, 1);
        },
        REMOVE_LOCATION(state, val) {
            state.shootingLocations = state.shootingLocations.filter(
                function (obj) {
                    return obj.id !== val.id;
                }
            );
        },
        ADD_LOCATION(state, val) {
            state.shootingLocations.unshift(val);
        },
        SET_LOCATIONS(state, val) {
            state.shootingLocations = val !== undefined ? val : null
        },
        UPDATE_LOCATIONS(state, newLocation){
            state.shootingLocations.forEach(function (oneLocation, index) {
                if (oneLocation.id === newLocation.id) {
                    // $th.$set(state.billingCompanies, index, newCompany);
                    //state.billingCompanies.splice(index, 1);
                    state.shootingLocations[index] = newLocation;
                    state.shootingLocations = [...state.shootingLocations];
                }
            });
        },
        REMOVE_APPLICANT(state, val) {
            state.applicants = state.applicants.filter(
                function (obj) {
                    return obj.id !== val.id;
                }
            );
        },
        SET_APPLICANTS(state, val) {
            state.applicants = val !== undefined ? val : null
        },
        ADD_APPLICANT(state, val) {
            state.applicants.unshift(val)
        },
        UPDATE_APPLICANTS(state, updatedApplicant) {
            state.applicants.forEach(function (oneApplicant, index) {
                if (oneApplicant.id === updatedApplicant.id) {
                    state.applicants[index] = updatedApplicant;
                    state.applicants = [...state.applicants];
                }
            });
        },
        SET_TALENT(state, val) {
            state.talentToPreview = val !== undefined ? val : null
        },
        SET_TALENT_RESULTS(state, val){
            state.talentSearchResults = val;
        },
        SET_TALENT_LIST_RESULTS(state, val){
            state.talentListResults = val;
        },
        SET_AUDITION(state, val){
            state.curAudition = {};
            state.curAudition = val;
        },
        ADD_SIDE(state, val) {
            state.sides.unshift(val);
        },
        SET_SIDES(state, val) {
            state.sides = val !== undefined ? val : null
        },
        REMOVE_SIDE(state, val) {
            state.sides = state.sides.filter(
                function (obj) {
                    return obj.id !== val.id;
                }
            );
        },
        SET_ROLE_INVITATION(state, val){
            state.curInvitation = val !== undefined ? val : null
        },
        SET_ROLE_INVITATIONS(state, val) {
            state.invitations = val !== undefined ? val : null
        },
        REMOVE_ROLE_INVITE(state, val) {
            state.invitations = state.invitations.filter(
                function (obj) {
                    return obj.id !== val.id;
                }
            );
        },
        ADD_SHORTLISTED(state, val) {
            state.shortlistedTalents[val.roleId] = val.roleUsers;
        },
        REMOVE_SHORTLISTED(state, val, auditionIndex) {
            state.shortlistedTalents[val.roleId].splice(auditionIndex, 1);
        },
    },
    actions: {
        getAllRoles({commit}) {
            if (this.state.curProduction.id !== null) {

                let ActingRole = Parse.Object.extend('ActingRole');
                let ProdQuery = new Parse.Query(ActingRole);

                const rolePointer = {"__type": "Pointer", "className": "Production", "objectId": this.state.curProduction.id};
                ProdQuery.equalTo("production", rolePointer);
                ProdQuery.include("talentPick");
                ProdQuery.addDescending("priority", "name");

                ProdQuery.find()
                    .then(function (results) {
                        if (results.length) {
                            results.forEach(function (oneResult) {
                                if (oneResult.attributes.shortlistedTalent) {
                                    let userQuery = new Parse.Query(Parse.User);
                                    userQuery.containedIn("objectId", oneResult.attributes.shortlistedTalent);
                                    userQuery.include("relatedMeta");
                                    userQuery.find().then(function (users) {
                                        commit('app/ADD_SHORTLISTED', {roleUsers: users, roleId: oneResult.id});
                                        commit('app/REFRESH_ROLES');
                                    });
                                }
                            });
                        }
                        commit('app/SET_ALL_ROLES', results);
                    })
                    .catch(function (err) {
                        console.log("Parse error", err.message);
                    });
            }
        }
    },
}
